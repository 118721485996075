// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// <<<<<<< HEAD
// <<<<<<< HEAD
// const local = "http://localhost:5051/";
// const host = 'https://broadcastsims.com';
// =======
// const server = "http://13.232.208.65:5051/";
// const local = "http://localhost:5051/";
// const host = 'http://localhost:4200';
// >>>>>>> bc96d1a63219e5e2eda4dadbb18b07582de08d25
// =======
const server = "https://www.broadcastsims.com:5051/"
const local = "https://www.broadcastsims.com:5051/"
//const local = "http://192.168.1.114:5051/"
const host = 'http://localhost:4200'
// >>>>>>> bc96d1a63219e5e2eda4dadbb18b07582de08d25

export const environment = {
  host: host,
  baseUrl : local,
  production: false,
  isMockEnabled: false,
  api: {}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
