import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service'

@Component({
  selector: 'app-student-sidebar',
  templateUrl: './student-sidebar.component.html',
  styleUrls: ['./student-sidebar.component.scss']
})
export class StudentSidebarComponent implements OnInit {

  activeTab: any;
  name: string
  student: any;
  @Input('activeTabIndex') activeTabIndex;

  baseUrl: string
  constructor(private router: Router, private service: ApiService) {
    this.baseUrl = this.service.baseUrl
  }

  ngOnInit() {
    this.activeTab = this.activeTabIndex
    if (localStorage.getItem('role') == 'student') {
      let student = JSON.parse(localStorage.getItem('user'))
      this.student = student
    } else {
      this.router.navigate(['/studentLoginPage']);
    }
    /** get changes from profile to sidebar service */
    this.service.currentMessage.subscribe(status => {
      if(status) {
        this.student = JSON.parse(localStorage.getItem('user'))
      }
    })
  }

  goTostudentDashboard() {
    // this.router.navigate(['/studentDashboard']);
    this.router.navigate(['/gradeBook']);
  }

  goTolessons() {
    this.router.navigate(['/lessons']);
  }

  // goTogradeBook() {
  //   this.router.navigate(['/gradeBook']);
  // }
  goToProfile() {
    this.router.navigate(['/studentProfile']);
  }

  logout() {
    localStorage.clear()
    this.router.navigate(['']);
  }

}
