import { Component, OnInit } from "@angular/core";
import { Router, NavigationExtras } from "@angular/router";
import { ApiService } from "../../services/api.service";

import { FormControl } from "@angular/forms";
@Component({
  selector: "app-teachLessons",
  templateUrl: "./teachLessons.component.html",
  styleUrls: ["./teachLessons.component.scss"]
})
export class TeachLessonsComponent implements OnInit {
  teacherLessons = [];
  user :any;

  simInfo = {
    url: "",
    pdfDoc: "",
    tutorialDoc: ""
  };
  simulationList = {
    switcher: [],
    replay: [],
    chyron: [],
    multiplayer: []
  };

  constructor(private router: Router, private service: ApiService) {
    this.user = JSON.parse(localStorage.getItem('user'))
   }


  ngOnInit() {
    this.getSimulations();
    this.getTeacher();
  }

  getSimulations() {
    this.service.getRequest(`v1/simulation/getAll`).subscribe((data: any) => {
      if (data.success) {
        if (data.simulation) {
          this.simulationList = data.simulation;
          console.log(data, "---sims");

          // data.simulation.map(sim => {
          //   if(sim['simulationType']==="switcher") {
          //     sim['label'] = 'Switcher';
          //     this.simulationList.push(sim);
          //   }
          //   if(sim['simulationType']==="replay") {
          //     sim['label'] = 'Replay';
          //     this.simulationList.push(sim);
          //   }
          //   if(sim['simulationType']==="chyron") {
          //     sim['label'] = 'Chyron';
          //     this.simulationList.push(sim);
          //   }
          //   if(sim['simulationType']==="multiplayer") {
          //     sim['label'] = 'Multiplayer';
          //     this.simulationList.push(sim);
          //   }
          // });
        }
      } else {
        this.service.showToasterError(data.message);
      }
    });
  }

  getTeacher() {
    let teacher = JSON.parse(localStorage.getItem("user"));
    this.service
      .getRequest(`v1/teacher/getTeacherById/${teacher._id}`)
      .subscribe(async (data: any) => {
        if (data.success) {
          this.teacherLessons = data.teacher["lessons"];
          console.log("checkdata",this.teacherLessons)
          await this.setLessonsScore();
        } else {
          this.service.showToasterError(data.message);
        }
      });
  }

  setLessonsScore() {
    if (this.simulationList && this.simulationList["switcher"].length) {
      this.simulationList["switcher"].map(sim => {
        if (this.teacherLessons && this.teacherLessons.length) {
          this.teacherLessons.map(lesson => {
            if (lesson["targetScore"] && lesson.simulationId === sim._id) {
              sim["targetScore"] = lesson["targetScore"];
            }
          });
        }
      });
    }
    if (this.simulationList && this.simulationList["replay"].length) {
      this.simulationList["replay"].map(sim => {
        if (this.teacherLessons && this.teacherLessons.length) {
          this.teacherLessons.map(lesson => {
            if (lesson["targetScore"] && lesson.simulationId === sim._id) {
              sim["targetScore"] = lesson["targetScore"];
            }
          });
        }
      });
    }
    if (this.simulationList && this.simulationList["chyron"].length) {
      this.simulationList["chyron"].map(sim => {
        if (this.teacherLessons && this.teacherLessons.length) {
          this.teacherLessons.map(lesson => {
            if (lesson["targetScore"] && lesson.simulationId === sim._id) {
              sim["targetScore"] = lesson["targetScore"];
            }
          });
        }
      });
    }
    if (this.simulationList && this.simulationList["multiplayer"].length) {
      this.simulationList["multiplayer"].map(sim => {
        if (this.teacherLessons && this.teacherLessons.length) {
          this.teacherLessons.map(lesson => {
            if (lesson["targetScore"] && lesson.simulationId === sim._id) {
              sim["targetScore"] = lesson["targetScore"];
            }
          });
        }
      });
    }
  }
  getSim(simId) {
    this.service
      .getRequest(`v1/simulation/getById/${simId}`)
      .subscribe((data: any) => {
        if (data.success) {
          this.simInfo = data.simulation;
        } else {
          this.service.showToasterError(data.message);
        }
      });
  }


  addLesson(lesson) {
    let teacher = JSON.parse(localStorage.getItem("user"));
    let data = {
      teacherId: teacher._id,
      lesson: []
    };
    let exists = false;
    if (this.teacherLessons && this.teacherLessons.length) {
      this.teacherLessons.map((_lesson, index) => {
        if (_lesson && _lesson.simulationId === lesson._id) {
          this.teacherLessons[index] = {
            simulationId: lesson["_id"],
            targetScore: lesson.targetScore,
            title: lesson["title"]
          };
          exists = true;
        } else {
          this.teacherLessons.push({
            simulationId: lesson["_id"],
            targetScore: lesson.targetScore,
            title: lesson["title"]
          });
        }
      });
      console.log(this.teacherLessons, "-lsjljlsjk");
      setTimeout(() => {
        data["lesson"] = this.teacherLessons;
        this.updateLesson(data);
      }, 1000);
    } else {
      data["lesson"].push({
        simulationId: lesson["_id"],
        targetScore: lesson.targetScore,
        title: lesson["title"]
      });
      this.addNewLesson(data);
    }
  }

  updateLesson(data) {
    this.service
      .updateRequest("v1/teacher/updateLesson", data)
      .subscribe((data: any) => {
        if (data.success) {
          localStorage.setItem("user", JSON.stringify(data.teacher));
          this.service.showToasterSuccess(data.message);
          this.service.changeMessage(true);
        } else {
          this.service.showToasterError(data.message);
        }
      });
  }
  addNewLesson(data) {
    this.service
      .postRequest("v1/teacher/addLesson", data)
      .subscribe((data: any) => {
        if (data.success) {
          localStorage.setItem("user", JSON.stringify(data.teacher));
          this.service.showToasterSuccess(data.message);
          this.service.changeMessage(true);
        } else {
          this.service.showToasterError(data.message);
        }
      });
  }


  openLesson(lesson) {
    
    console.log(lesson);
    
    /** requrest api to send sessionId of student and redirecting url */
    this.service
      .getRequest(`v1/teacher/getTeacherSessionId/${this.user["_id"]}`)
      .subscribe((data: any) => {
        if (data.success) {
          let _data = data["sessionId"];
           let lessonUrl = lesson.url + "&teacherSessionId=" + JSON.stringify(_data)+ '&type=teacher';
          window.open(lessonUrl, '_blank');
          console.log(lessonUrl, "------this.lessonUrl");
        }
  })
}

movetopdf(pdf)
{
  console.log(pdf)
//  window.location = pdf ;
window.open(`${pdf}?teacherSessionId="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZTNkMTM0ZTllOGYxNzFiNWJkOWViZGEiLCJjdXJyZW50VGltZSI6MTU5NzY2OTIzNjAwNywiaWF0IjoxNTk3NjY5MjM2fQ.Lq77n9J20fYjvYGSDbSJcFw9a_bKN7UtOE61v2ieIi0"&type=teacher`,"_blank");
  // ("https://www.africau.edu/images/default/sample.pdf");
  
}
}
