import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApiService } from "../../services/api.service";
import { MatDialog } from "@angular/material/dialog";
import { ModalComponent } from "../../Web/modal/modal.component";
import { Router } from "@angular/router";

// <<<<<<< HEAD
// <<<<<<< HEAD
import {saveAs} from 'file-saver';

// =======
// >>>>>>> bc96d1a63219e5e2eda4dadbb18b07582de08d25
// =======
// >>>>>>> bc96d1a63219e5e2eda4dadbb18b07582de08d25
@Component({
  selector: "app-conference-page",
  templateUrl: "./conference-page.component.html",
  styleUrls: ["./conference-page.component.scss"]
})
export class ConferencePageComponent implements OnInit {
  ConferenceForm: FormGroup;
  hasFormErrors: boolean = false;

// <<<<<<< HEAD
// <<<<<<< HEAD
  file = '../../assets/Special STN2020  Pricing.pdf';

// =======
// >>>>>>> bc96d1a63219e5e2eda4dadbb18b07582de08d25
// =======
// >>>>>>> bc96d1a63219e5e2eda4dadbb18b07582de08d25
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private service: ApiService,
    public dialog: MatDialog
  ) {}
  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.ConferenceForm = this.fb.group({
      companyName: [
        "",
// <<<<<<< HEAD
// <<<<<<< HEAD
        [Validators.required, Validators.minLength(3), Validators.maxLength(50)]
      ],
      name: [
        "",
        [Validators.required, Validators.minLength(3), Validators.maxLength(50)]
// =======
// =======
// >>>>>>> bc96d1a63219e5e2eda4dadbb18b07582de08d25
      //   [Validators.required, Validators.minLength(3), Validators.maxLength(15)]
      // ],
//       name: [
//         "",
//         [Validators.required, Validators.minLength(3), Validators.maxLength(15)]
// <<<<<<< HEAD
// >>>>>>> bc96d1a63219e5e2eda4dadbb18b07582de08d25
// =======
// >>>>>>> bc96d1a63219e5e2eda4dadbb18b07582de08d25
      ],
      email: ["", [Validators.required, Validators.email]],
      number: ['', [Validators.required,Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(15)]],
      message: ["", Validators.required]
    });
  }

  /** ACTIONS */
  prepareContactUs() {
    const controls = this.ConferenceForm.controls;

    let formData = {};
    formData["companyName"] = controls["companyName"].value;
    formData["name"] = controls["name"].value;
    formData["email"] = controls["email"].value;
    formData['number'] = controls['number'].value;
    formData["message"] = controls["message"].value;

    return formData;
  }
  get f() {
    return this.ConferenceForm.controls;
  }

  onSubmit() {
    this.hasFormErrors = false;
    const controls = this.ConferenceForm.controls;
    /** check form */
    if (this.ConferenceForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.hasFormErrors = true;
      return;
    } else {
      const data = this.prepareContactUs();
      this.service
        .postRequest("v1/admin/conference", data)
        .subscribe((data: any) => {
          if (data.success) {
            this.createForm();
            this.openDialog(data['conference']);
        } else {
            this.service.showToasterError(data.message);
          }
        });
    }
  }

  openDialog(_data): void {
// <<<<<<< HEAD
// <<<<<<< HEAD
     saveAs(this.file);
// =======
// >>>>>>> bc96d1a63219e5e2eda4dadbb18b07582de08d25
// =======
// >>>>>>> bc96d1a63219e5e2eda4dadbb18b07582de08d25
    const dialogRef = this.dialog.open(ModalComponent, {
      width: "30%",
      height: "40%",
      data: {
        title: "Thank You for visiting us!",
        body: "Want to continue to broadcastsims.com ?",
        // data: _data
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.router.navigate(["/"]);
      }
      console.log(result);
    });
  }
}
