import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "../../services/api.service";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { FormControl } from "@angular/forms";
import {
  MatAutocompleteSelectedEvent,
  MatAutocomplete
} from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { Observable } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { ModalComponent } from "../../Web/modal/modal.component";

@Component({
  selector: "app-view-class",
  templateUrl: "./view-class.component.html",
  styleUrls: ["./view-class.component.scss"]
})
export class ViewClassComponent implements OnInit {
  classInfo: any = {};
  studentsInfo: any = [];
  assignedLesson: any = [];
  selectedStudent = false;
  studentlesson:any;
  disableSelect = new FormControl(false);
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  lessonCtrl = new FormControl();
  filteredLessons: Observable<Object[]>;
  allLessons = [];
  selectedLessons = [];
  studentsLessonsData: any = [];
  addedLessonsData: any = [];
  studentsLessonLength = 0;
  hasViewedLessons = false;

  @ViewChild("lessonInput", { static: false }) lessonInput: ElementRef<
    HTMLInputElement
  >;
  @ViewChild("auto", { static: false }) matAutocomplete: MatAutocomplete;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: ApiService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params && params.id) {
        let classId = params.id;
        this.service
          .getRequest(`v1/teacher/getClassDetails?classId=${classId}`)
          .subscribe((data: any) => {
            if (data.success) {
              this.classInfo = data["class"];
              // if (
              //   data["class"]["teacherId"] &&
              //   data["class"]["teacherId"]["lessons"]
              // ) {
              //   this.allLessons = data["class"]["teacherId"]["lessons"];
              // }
              this.getAllLessons();
              this.getStudents(this.classInfo);
            } else {
              this.service.showToasterError(data.message);
            }
          });
      } else {
        this.router.navigate(["teachclasses"]);
      }
    });
  }

  allowLesson(lesson) {
    console.log(lesson, '---in com')
  }

  getAllLessons() {
    this.service.getAllRequest('v1/simulation/getAllList').subscribe((data: any) => {
      if (data.success) {
        if (data.simulation) {
          this.allLessons = data.simulation;
          console.log("checklesson",this.allLessons);
        }
      } else {
        this.service.showToasterError(data.message);
      }
    });
  }

  getStudents(classData) {
    
    this.service
      .getRequest(
        `v1/student/getStudents/${classData.teacherId._id}?code=${classData.code}`
      )
      .subscribe((data: any) => {
        if (data.success) {
          this.studentsInfo = data["student"];
          console.log("ths.student23",this.studentsInfo);
           this.studentlesson = data["student"];
          if (data["student"] && data["student"].length) {
            data["student"].map(student => {
              if (student["lessons"] && student["lessons"].length) {
                this.hasViewedLessons= true;
                student["lessons"].map(lesson => {
                  this.studentsLessonsData.push(lesson);
                });
              }
            });
            this.studentsLessonLength = this.studentsLessonsData.length;

          }
        } else {
          this.service.showToasterError(data.message);
        }
      });
  }

  deleteStudentLesson(id) {
    this.service
      .deleteRequest(`v1/studentLesson/deleteStudentLesson/${id}`)
      .subscribe((data: any) => {
        if (data.success) {
          this.service.showToasterSuccess(data.message);
        } else {
          this.service.showToasterError(data.message);
        }
      });
  }
  add(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;
      // Add our lesson
      if ((value || "").trim()) {
        this.studentsLessonsData.push(value.trim());
       // console.log("this-text",this.studentsLessonsData);
      }
      // Reset the input value
      if (input) {
        input.value = "";
      }
      this.lessonCtrl.setValue(null);
    }
  }

  removeLesson(lesson): void {
    if (lesson && lesson['_id']) {
      this.openDeleteModal(lesson);
    } else {
      const index = this.studentsLessonsData.indexOf(lesson);
      const addedIndex = this.addedLessonsData.indexOf(lesson);
      if (index >= 0) {
        this.studentsLessonsData.splice(index, 1);
      }
      if (addedIndex >= 0) {
        this.addedLessonsData.splice(index, 1);
      }
    }
  }
  openDeleteModal(lesson) {
    const dialogRef = this.dialog.open(ModalComponent, {
      width: "40%",
      height: "40%",
      data: {
        title: "Delete Assigned Lesson",
        body:
          `Are you sure you want to remove ${lesson['title']} from assigned lesson list ?`
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const index = this.studentsLessonsData.indexOf(lesson);
        const addedIndex = this.addedLessonsData.indexOf(lesson);
        if (index >= 0) {
          this.studentsLessonsData.splice(index, 1);
        }
        if (addedIndex >= 0) {
          this.addedLessonsData.splice(index, 1);
        }
        this.deleteStudentLesson(lesson['_id']);
      }
    });
  }

  selected(event: MatAutocompleteSelectedEvent, student): void {
    let exists = false;
    if (this.studentsLessonsData && this.studentsLessonsData.length) {
      this.studentsLessonsData.map(_lesson => {
        if (
          _lesson["studentId"] === student["_id"] &&
          _lesson["title"] === event.option.value["title"]
        ) {
          exists = true;
          return;
        }
      });
      console.log("sti",this.studentsLessonsData);
    }
    if (!exists) {

      let _obj = {
        simulationId: event.option.value["simulationId"],
        studentId: student["_id"],
        classId: this.classInfo["_id"],
        title: event.option.value["title"],
        targetScore: event.option.value["targetScore"]
      };
      this.studentsLessonsData.push(_obj);
      this.addedLessonsData.push(_obj);
      this.lessonInput.nativeElement.value = "";
      this.lessonCtrl.setValue(null);
    }
  }

  updateStudentsLesson() {
    this.addedLessonsData.map(studentLesson => {
      if (studentLesson["_id"]) {
        /** remove _id before adding in list  */
        delete studentLesson["_id"];
        delete studentLesson["__v"];
      }
    });
    this.service
      .postRequest(
        `v1/studentLesson/updateStudentsLesson`,
        this.addedLessonsData
      )
      .subscribe((data: any) => {
        if (data.success) {
          console.log("updatedata",data);
          // this.getStudents(this.classInfo);
          this.router.navigate(["/teachclasses"]);
          this.service.showToasterSuccess(data.message);
        } else {
          this.service.showToasterError(data.message);
        }
      });
  }
}
