import { Component,Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from "@angular/material/dialog";
import { ApiService } from "../../services/api.service";
@Component({
  selector: 'app-tmodal',
  templateUrl: './tmodal.component.html',
  styleUrls: ['./tmodal.component.scss']
})
export class TmodalComponent implements OnInit {
  modalBody = {
    title: "",
    body: ""
  };
  defaultButton = "Yes";
  downloadData = {};

  constructor(
    public dialogRef: MatDialogRef<TmodalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: ApiService,  ) {
    dialogRef.disableClose = true;
    this.modalBody = data;
  }

  ngOnInit() {
    if (
      this.modalBody["title"] === "Welcome" ||
      this.modalBody["title"] === "Thank you"
    ) {
      //this.defaultButton = "Got it";
    }
    // if(this.modalBody['data']) {
    //   console.log(this.modalBody['data'], '---here');
    //   this.downloadData= this.modalBody['data'];
    // }
  }

  // downloadPdf() {
  //   let doc = new jsPDF(this.downloadData);
  //   doc.save("conference.pdf");
  // }

  confirm() {

    if (this.defaultButton == "Yes") {
      JSON.parse(localStorage.getItem("payload"))
      this.service.postRequest(`v1/student/transferStudent`,JSON.parse(localStorage.getItem("payload"))).subscribe((res:any)=>
    {
      if(res.success)
      {
        this.service.showToasterSuccess("succesfully transfer!")
      }
    })
      this.cancel();
    } else {
      this.dialogRef.close(true);
    }
  }
  cancel() {
    this.dialogRef.close(false);
  }
}
