import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "../../services/api.service";

import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from "@angular/material/dialog";
import { SimModalComponent } from "../../Web/sim-modal/sim-modal.component";
import { S } from "@angular/cdk/keycodes";

@Component({
  selector: "app-add-lesson",
  templateUrl: "./add-lesson.component.html",
  styleUrls: ["./add-lesson.component.scss"]
})
export class AddLessonComponent implements OnInit {
  lessonInfo: any = {};
  editRequest = false;
  lessonSelected = false;

  constructor(
    private router: Router,
    private service: ApiService,
    public dialog: MatDialog
  ) {}

  simulationList = [];

  ngOnInit() {
    this.getSimulations();
  }

  getSimulations() {
    this.service.getRequest(`v1/simulation/getAll`).subscribe((data: any) => {
      if (data.success) {
        this.simulationList = data.simulation;
      } else {
        this.service.showToasterError(data.message);
      }
    });
  }

  // showSim(simId) {
  //   let simInfo = {};
  //   if (simId) {
  //     this.service
  //       .getRequest(`v1/simulation/getById/${simId}`)
  //       .subscribe((data: any) => {
  //         if (data.success) {
  //           simInfo = data.simulation;
  //           this.openModal(simInfo);
  //         } else {
  //           this.service.showToasterError(data.message);
  //         }
  //       });
  //   }
  // }
  // openModal(simInfo) {
  //   const dialogRef = this.dialog.open(SimModalComponent, {
  //     width: "40%",
  //     height: "40%",
  //     data: {
  //       title: "Simulation Details",
  //       body: simInfo
  //     }
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (!result) {
  //       this.lessonInfo["simulation"] = "";
  //     } else 
  //         this.lessonSelected = true;
  //     console.log(this.lessonInfo)
  //   });
  // }

  lessonChange(simId) {
    this.lessonInfo["simulationId"] = simId;
    this.lessonSelected = true;
  }
  addLesson() {
    let teacher = JSON.parse(localStorage.getItem("user"));
    if (this.lessonInfo) {
      let data = {
        teacherId: teacher._id,
        lesson: this.lessonInfo
      };
      this.service
        .postRequest("v1/teacher/addLesson", data)
        .subscribe((data: any) => {
          if (data.success) {
            localStorage.setItem("user", JSON.stringify(data.teacher));
            this.service.showToasterSuccess(data.message);
            this.router.navigate(["teachLessons"]);
            this.service.changeMessage(true);
          } else {
            this.service.showToasterError(data.message);
          }
        });
    }
  }

  goToteachLessons() {
    this.router.navigate(["/teachLessons"]);
  }
}
