import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "../../services/api.service";

@Component({
  selector: "app-grade-book",
  templateUrl: "./grade-book.component.html",
  styleUrls: ["./grade-book.component.scss"]
})
export class GradeBookComponent implements OnInit {
  studentInfo: any;
  classInfo: any;
  studentLessons = [];

  constructor(private service: ApiService, private router: Router) {}

  ngOnInit() {
    this.studentInfo = JSON.parse(localStorage.getItem("user"));
    this.getStudent(this.studentInfo);
    this.getClass(this.studentInfo["classCode"]);
  }

  getStudent(studentInfo) {
    this.service
      .getRequest(
        `v1/studentLesson/getAll/${studentInfo['_id']}`
      )
      .subscribe((data: any) => {
        if (!data.success) {
          this.service.showToasterError(data.message);
        } else {
          this.studentLessons = data.studentLesson;
        }
      });
  }

  getClass(classCode) {
    this.service
      .getRequest(`v1/teacher/getClassDetails?code=` + classCode)
      .subscribe((data: any) => {
        if (data.success) {
          this.classInfo = data['class'];
        } else {
          this.service.showToasterError(data.message);
        }
      });
  }
}
