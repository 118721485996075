import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service'

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  ContactUsForm: FormGroup;
	hasFormErrors: boolean = false;

  constructor(
    private fb: FormBuilder,
    private service: ApiService
    ) { }

  ngOnInit() {
    this.createForm();
  }

  createForm() {		
		this.ContactUsForm = this.fb.group({
      companyName: [
        "",
        [Validators.required, Validators.minLength(3), Validators.maxLength(50)]
      ],
			name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
			mail: ['', [Validators.required, Validators.email]],
			number: ['', [Validators.required,Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(15)]],
			message: ['', Validators.required],
		});
  }

  	/** ACTIONS */
	prepareContactUs() {
    const controls = this.ContactUsForm.controls;
    
		let formData = {};
		formData['companyName'] = controls['companyName'].value;
		formData['name'] = controls['name'].value;
		formData['mail'] = controls['mail'].value;
		formData['number'] = controls['number'].value;
    formData['message'] = controls['message'].value;
    
		return formData;
	}
  get f() { 
    console.log(this.ContactUsForm, '--------form');
    
    return this.ContactUsForm.controls;
   }

  onSubmit() {
		this.hasFormErrors = false;
    const controls = this.ContactUsForm.controls;
		/** check form */
		if (this.ContactUsForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			this.hasFormErrors = true;
			return;
		} else {
      const data = this.prepareContactUs();
      this.service.postRequest('v1/admin/contact-us', data)
      .subscribe((data: any) => {
        if (data.success) {
          this.service.showToasterSuccess(data.message)
          this.createForm();
        } else {
          this.service.showToasterError(data.message)
        }
      })
    }
  }

  goTodashboard(){
    
  }
}
