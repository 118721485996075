import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "../../services/api.service";
import { MatDialog } from "@angular/material/dialog";
import { ModalComponent } from "../../Web/modal/modal.component";

@Component({
  selector: "app-admin-teacher",
  templateUrl: "./admin-teacher.component.html",
  styleUrls: ["./admin-teacher.component.scss"]
})
export class AdminTeacherComponent implements OnInit {
  _id: string;
  allList: any;

  constructor(
    private router: Router,
    private service: ApiService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.getTeachers();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ModalComponent, {
      width: "30%",
      height: "40%",
      data: {
        title: "Welcome",
        body: "To get started, please tap the Invite Teacher button"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      localStorage.setItem("inviteModal", "false");
      // this.getTeachers()
    });
  }

  getTeachers() {
    let user = JSON.parse(localStorage.getItem("user"));
    let fm = {
      _id: user._id
    };
    this.service.postRequest("v1/admin/teachers", fm).subscribe((data: any) => {
      if (data.success) {
        this.allList = data.users;
        if (
          !this.allList.length &&
          localStorage.getItem("inviteModal") === "true"
        ) {
          this.openDialog();
        } else {
          this.allList = data.users;
        }
      } else {
        this.service.showToasterError(data.message);
      }
    });
  }

  goToaddTeacher() {
    this.router.navigate(["admin/teacher/add"]);
  }

  editTeacher(id: string) {
    this.router.navigate(["admin/teacher/" + id]);
  }

  deleteTeacher(id: string) {
    if (id) {
      const dialogRef = this.dialog.open(ModalComponent, {
        width: "40%",
        height: "40%",
        data: {
          title: "Delete Teacher",
          body: "Are you sure you want to delete this record !"
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.service
            .deleteRequest("v1/admin/teacher/" + id)
            .subscribe((data: any) => {
              if (data.success) {
                this.service.showToasterSuccess(data.message);
                this.getTeachers();
              } else {
                this.service.showToasterError(data.message);
              }
            });
        }
      });
    }
  }
}
