import { Component, OnInit, Inject,ElementRef, ViewChild } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from "@angular/material/dialog";
import { ApiService } from '../../services/api.service'

@Component({
  selector: "app-sim-modal",
  templateUrl: "./sim-modal.component.html",
  styleUrls: ["./sim-modal.component.css"]
})
export class SimModalComponent implements OnInit {
  modalBody = {
    title: "",
    body: {name: '', url: ''}
  };
  defaultButton = "Confirm";
  baseUrl = '';
  @ViewChild("videoPlayer", { static: false }) videoplayer: ElementRef;

  constructor(
    private service: ApiService,
    public dialogRef: MatDialogRef<SimModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;
    this.modalBody = data;
    this.baseUrl = this.service.baseUrl;
  }

  ngOnInit() {}

  toggleVideo() {
    this.videoplayer.nativeElement.play();
  }
  confirm() {
    this.dialogRef.close(true);
  }
  cancel() {
    this.dialogRef.close(false);
  }
}
