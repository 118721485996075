import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service'

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss']
})
export class AdminLoginComponent implements OnInit {
  user: any = {}
  constructor(private router: Router, private service: ApiService) { }

  ngOnInit() {
  }

  submit() {
    this.service.postRequest('v1/admin/login', this.user)
      .subscribe((data: any) => {
        if (data.success) {
          this.user = {}
          localStorage.setItem('user', JSON.stringify(data.user))
          localStorage.setItem('role', 'admin')
          localStorage.setItem('inviteModal', 'true')
          this.router.navigate(['admin/teacher'])
        } else {
          this.service.showToasterError(data.message)
        }
      })
  }
}
