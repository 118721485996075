import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-advertism',
  templateUrl: './advertism.component.html',
  styleUrls: ['./advertism.component.scss']
})
export class AdvertismComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
