import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-header-top',
  templateUrl: './header-top.component.html',
  styleUrls: ['./header-top.component.scss']
})
export class HeaderTopComponent implements OnInit {

  constructor(private router:Router) {
  }

  ngOnInit() {
  }
  goTologinPage(){
    this.router.navigate(['/loginPage']);
  }
  goTohomepage(){
    this.router.navigate(['']);
  }
  goToschoolregister(){
    this.router.navigate(['/educators']);
  }
  goTofreeDemo(){
    this.router.navigate(['/freeDemo']);
  }
  goTocontactUs(){
    this.router.navigate(['/contactUs']);
  }
  goToPage(type) {
    this.router.navigate(['/page/'+type])
  }
  
}
