import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "../../services/api.service";

@Component({
  selector: "app-teacher-login",
  templateUrl: "./teacher-login.component.html",
  styleUrls: ["./teacher-login.component.scss"]
})
export class TeacherLoginComponent implements OnInit {
  user: any = {};
  constructor(private router: Router, private service: ApiService) {}

  ngOnInit() {
    if (localStorage.getItem("role") == "teacher")
      // this.router.navigate(['dashboard'])
      this.router.navigate(["teachclasses"]);
  }

  submit() {
    this.service
      .postRequest("v1/teacher/login", this.user)
      .subscribe((data: any) => {
        if (data.success) {
          this.user = {};
          localStorage.setItem("user", JSON.stringify(data.user));
          localStorage.setItem("role", "teacher");
          // this.router.navigate(['dashboard'])
          this.router.navigate(["teachclasses"]);
        } else {
          this.service.showToasterError(data.message);
        }
      });
  }
}
