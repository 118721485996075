import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "../../services/api.service";
import { MatDialog } from "@angular/material/dialog";
import { ModalComponent } from "../modal/modal.component";
import { FormBuilder, FormGroup, Validators, FormArray, AbstractControl  } from '@angular/forms';

@Component({
  selector: "app-simulation-create",
  templateUrl: "./simulation-create.component.html",
  styleUrls: ["./simulation-create.component.css"]
})

export class SimulationCreateComponent implements OnInit {
  insert = false;

  SimulationForm: FormGroup;
  hasFormErrors: boolean = false;
  
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private service: ApiService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    if (
      !localStorage.getItem("user") &&
      localStorage.getItem("role") !== "admin"
    ) {
      this.router.navigate(["/admin"]);
    }
    this.createForm();
  }

  createForm() {
    this.SimulationForm = this.fb.group({
			title: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(15)]],
			url: ['', Validators.required],
			simulationType: ['', Validators.required],
// <<<<<<< HEAD
// <<<<<<< HEAD
// 			// maxViewers: ['', Validators.required],
// =======
// >>>>>>> bc96d1a63219e5e2eda4dadbb18b07582de08d25
// =======
// >>>>>>> bc96d1a63219e5e2eda4dadbb18b07582de08d25
			pdfDoc: this.fb.array([
        this.fb.control(null)
      ]),
			tutorialDoc:this.fb.array([
        this.fb.control(null)
      ]),
		});
  }

  /** pdf  */
  addPdf(): void {
    (this.SimulationForm.get('pdfDoc') as FormArray).push(
      this.fb.control(null)
    );
  }

  removePdf(index) {
    (this.SimulationForm.get('pdfDoc') as FormArray).removeAt(index);
  }

  getPdfFormControls(): AbstractControl[] {
    return (<FormArray> this.SimulationForm.get('pdfDoc')).controls
  }

  /** tutorial video */

  addTutorial(): void {
    (this.SimulationForm.get('tutorialDoc') as FormArray).push(
      this.fb.control(null)
    );
  }

  removeTutorial(index) {
    (this.SimulationForm.get('tutorialDoc') as FormArray).removeAt(index);
  }

  getTutorialFormControls(): AbstractControl[] {
    return (<FormArray> this.SimulationForm.get('tutorialDoc')).controls
  }

  /** set form */
  get f() { 
    return this.SimulationForm.controls; }

  prepareForm() {
    const controls = this.SimulationForm.controls;
    
		let formData = {};
		formData['title'] = controls['title'].value;
// <<<<<<< HEAD
// <<<<<<< HEAD
// 		// formData['maxViewers'] = controls['maxViewers'].value;
// =======
// >>>>>>> bc96d1a63219e5e2eda4dadbb18b07582de08d25
// =======
// >>>>>>> bc96d1a63219e5e2eda4dadbb18b07582de08d25
		formData['url'] = controls['url'].value;
		formData['simulationType'] = controls['simulationType'].value;
    if(controls['pdfDoc'].value[0] !=null) {
      formData['pdfDoc'] = controls['pdfDoc'].value;
    }
    if(controls['tutorialDoc'].value[0] !=null) {
      formData['tutorialDoc'] = controls['tutorialDoc'].value;
    }
		return formData;
  }
  onSubmit() {
		this.hasFormErrors = false;
    const controls = this.SimulationForm.controls;
		/** check form */
		if (this.SimulationForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			this.hasFormErrors = true;
			return;
    } else { 
      let data = this.prepareForm();
      this.add(data);
    }
  }

  add(_data) {
    this.service
      .postRequest("v1/simulation/add", _data)
      .subscribe((data: any) => {
        if (data.success) {
          const dialogRef = this.dialog.open(ModalComponent, {
            width: "40%",
            height: "40%",
            data: {
              title: "Thank you",
              body: `'${_data["title"]}' Simulation added successfully`
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            // this.ngOnInit();
            window.location.reload();
          });
          this.service.showToasterSuccess(data.message);
        } else {
          this.service.showToasterError(data.message);
        }
      });
  }

}
