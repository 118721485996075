import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service'

@Component({
  selector: 'app-admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.scss']
})
export class AdminSidebarComponent implements OnInit {

  activeTab: any;
  school = {
    profilePic: '',
    name : '',
    accountAdministrator: ''

  };
  @Input('activeTabIndex') activeTabIndex;

  baseUrl: string
  constructor(private router: Router, private service: ApiService) {
    this.baseUrl = this.service.baseUrl
  }

  ngOnInit() {
    this.activeTab = this.activeTabIndex
    if (localStorage.getItem('role') == 'admin') {
      let user = JSON.parse(localStorage.getItem('user'))
      this.school = user
    } else {
      this.router.navigate(['/admin/login']);
    }

    /** get changes from profile to sidebar service */
    this.service.currentMessage.subscribe(status => {
      if(status) {
        this.school = JSON.parse(localStorage.getItem('user'))
      }
    })
  }

  goTodashboard() {
    this.router.navigate(['/admin/dashboard']);
  }

  goToteachers() {
    this.router.navigate(['/admin/teacher']);
  }

  goToProfile() {
    this.router.navigate(['/admin/profile']);
  }
  
  goToChangePassword() {
    this.router.navigate(['/admin/password']);
  }

  logout() {
    localStorage.clear()
    this.router.navigate(['']);
  }

}
