import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "../../services/api.service";

import {
  MatDialog
} from "@angular/material/dialog";
import { ModalComponent } from "../../Web/modal/modal.component";

@Component({
  selector: "app-teach-classes",
  templateUrl: "./teach-classes.component.html",
  styleUrls: ["./teach-classes.component.scss"]
})
export class TeachClassesComponent implements OnInit {
  allClasses = [];
  teacher = {};
  constructor(
    private router: Router,
    private service: ApiService,
    public dialog: MatDialog
  ) {}
  ngOnInit() {
    this.teacher = JSON.parse(localStorage.getItem('user'));
    this.getAll();
  }

  goToaddClasses() {
    this.router.navigate(["/addClasses"]);
  }

  editClass(classId,name) {
    localStorage.setItem("name",JSON.stringify(name))
    this.router.navigate(["/addClasses/" + classId]);
  }

  getAll() {
    this.service
      .getAllRequest("v1/teacher/getAllClasses/"+this.teacher['_id'])
      .subscribe((data: any) => {
        if (data.success) {
          this.allClasses = data["classes"];
          console.log("add class",this.allClasses);
        } else {
          this.service.showToasterError(data.message);
        }
      });
  }

  viewClass(classId) {
    this.router.navigate(["/viewClass/" + classId]);
  }

  deleteClass(classId) {
    if (classId) {
      const dialogRef = this.dialog.open(ModalComponent, {
        width: "40%",
        height: "40%",
        data: {
          title: "Delete Class",
          body:
            "You are about to delete your class. Deleting your class will delete all students in the class. Do you want to proceed?"
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.service
            .deleteRequest(`v1/teacher/deleteClass/${classId}`)
            .subscribe((data: any) => {
              if (data.success) {
                this.service.showToasterSuccess(data.message);
                this.getAll();
              } else {
                this.service.showToasterError(data.message);
              }
            });
        }
      });
    }
  }
}
