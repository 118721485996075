import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "../../services/api.service";

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.css"]
})
export class AdminComponent implements OnInit {
  userExists = false;
  user = {
    email: '',
    password: ''
  };
  constructor(private router: Router, private service: ApiService) {}

  ngOnInit() {
    if (
      localStorage.getItem("user") &&
      localStorage.getItem("role") === "admin"
    ) {
      this.userExists = true;
    }
  }

  submit() {
    this.service
      .postRequest("v1/admin/login", this.user)
      .subscribe((data: any) => {
        if (data.success) {
          this.userExists = true;
          localStorage.setItem("user", JSON.stringify(data.user));
          localStorage.setItem("role", ("admin"));
        } else {
          this.service.showToasterError(data.message);
        }
      });
  }
}
