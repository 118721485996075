import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../services/api.service";
import { Router, NavigationExtras } from "@angular/router";
import { environment } from "../../../environments/environment";
import { TargetLocator } from "selenium-webdriver";

@Component({
  selector: "app-lessons",
  templateUrl: "./lessons.component.html",
  styleUrls: ["./lessons.component.scss"]
})
export class LessonsComponent implements OnInit {
  baseApplicationUrl = environment.host;
  assignedLessons = [];
  studentLessons: any = {};
  studentInfo = {};
  simInfo = {};
  lessonUrl: any;
  nativeWindow: any;
  tutoriallist: any;
  sessionid: any;

  constructor(private router: Router, private service: ApiService) {}

  ngOnInit() {
    this.studentInfo = JSON.parse(localStorage.getItem("user"));
    // this.updateFirstLesson(this.studentInfo["_id"]);
    this.getStudent(this.studentInfo);

    this.service.getAllRequest('v1/simulation/getAllList').subscribe((data: any) => {
     this.tutoriallist = data.simulation.filter(item => item.title == "Tutorial") 
     console.log("ce",this.tutoriallist);
    });
  }

  updateFirstLesson(studentId) {
    this.service
      .updateRequest(`v1/studentLesson/updateFirstLesson/${studentId}`, {
        status: -1
      })
      .subscribe((data: any) => {});
  }

  getStudent(student) {
    this.service
      .getRequest(`v1/studentLesson/getAll/${student["_id"]}`)
      .subscribe((data: any) => {
        if (data.success) {
          // this.assignedLessons = data.student["assignedLesson"];
          this.sessionid = data.studentLesson[0].sessionId;
          console.log("gfkj",this.sessionid);
          this.studentLessons = data.studentLesson;
        } else {
          this.service.showToasterError(data.message);
        }
      });
  }

  getSim(simId) {
    this.service
      .getRequest(`v1/simulation/getById/${simId}`)
      .subscribe((data: any) => {
        if (data.success) {
          this.simInfo = data.simulation;
        } else {
          this.service.showToasterError(data.message);
        }
      });
  }

  watchvideo(url)
  {
    window.open(`${url}&sessionId=${this.sessionid}&type=student`)
  }

  openLesson(lesson) {
    /** requrest api to send sessionId of student and redirecting url */
    this.service
      .getRequest(`v1/studentLesson/getStudentSessionId/${lesson["_id"]}`)
      .subscribe((data: any) => {
        if (data.success) {
          let _data = data["sessionId"];

          this.lessonUrl = lesson.simulationId.url + "&sessionId=" + JSON.stringify(_data)+ '&type=student';

          window.open(this.lessonUrl, '_blank');
          console.log(this.lessonUrl, "------this.lessonUrl");
        } else {
          this.service.showToasterError(data.message);
        }
      });
  }
}
