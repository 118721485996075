import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-student-register',
  templateUrl: './student-register.component.html',
  styleUrls: ['./student-register.component.scss']
})
export class StudentRegisterComponent implements OnInit {
  student = {
    profilePic: '',
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    phone: null,
    classCode: ''
  };
  file: any
  public imagePath;
  imgURL: any;
  constructor(private router: Router, private service: ApiService) { }

  ngOnInit() {
  }

  onChange(event) {
    var files = event.srcElement.files;
    this.file = files[0];
    this.loadImage(this.file.type, files);
  }

  loadImage(mimeType, files) {
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]); 
    reader.onload = (_event) => { 
      this.imgURL = reader.result; 
    }
  }

  onBoard() {

    let fd = new FormData()
    fd.append('pic', this.file)
    fd.append('name', this.student.firstname + ' ' + this.student.lastname)
    fd.append('email', this.student.email)
    fd.append('password', this.student.password)
    fd.append('phone', this.student.phone)
    fd.append('classCode', this.student.classCode)

    this.service.postRequest('v1/student/register', fd)
      .subscribe((data: any) => {
        if (data.success) {
          this.service.showToasterSuccess(data.message)
          this.router.navigate(['studentLoginPage']);
        } else {
          this.service.showToasterError(data.message)
        }
      })
  }

}
