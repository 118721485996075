import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/api.service'

@Component({
  selector: 'app-admin-profile',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['./admin-profile.component.scss']
})
export class AdminProfileComponent implements OnInit {

  user: any = {}
  file: any
  baseUrl: string
  public imagePath;
  imgURL: any;
  constructor(private router: Router, private route: ActivatedRoute, private service: ApiService) { }


  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'))
    this.user.firstname = this.user.name.split(' ')[0];
    this.user.lastname = this.user.name.split(' ')[1];
    this.baseUrl = this.service.baseUrl
  }

  onChange(event) {
    var files = event.srcElement.files;
    this.file = files[0];
    this.loadImage(this.file.type, files);
  }

  loadImage(mimeType, files) {
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]); 
    reader.onload = (_event) => { 
      this.imgURL = reader.result; 
      this.user.profilePic = this.imgURL;
    }
  }
  
  submit() {

    let fd = new FormData()
    fd.append('pic', this.file)
    fd.append('_id', this.user._id)
    fd.append('name', this.user.name)
    fd.append('email', this.user.email)
    fd.append('phone', this.user.phone)

    this.service.updateRequest('v1/admin', fd)
      .subscribe((data: any) => {
        if (data.success) {
          this.user = data.user
          this.user.firstname = this.user.name.split(' ')[0];
          this.user.lastname = this.user.name.split(' ')[1];
          localStorage.setItem('user', JSON.stringify(data.user))
          this.service.showToasterSuccess(data.message)
          this.service.changeMessage(true);
        } else {
          this.service.showToasterError(data.message)
        }
      })
  }

}
