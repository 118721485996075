import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service'

@Component({
  selector: 'app-admin-password',
  templateUrl: './admin-password.component.html',
  styleUrls: ['./admin-password.component.scss']
})
export class AdminPasswordComponent implements OnInit {

  user: any = {}
  userId: string
  constructor(private router: Router, private service: ApiService) { }

  ngOnInit() {
    this.userId = JSON.parse(localStorage.getItem('user'))._id
  }


  submit() {
    this.user._id = this.userId
    this.service.updateRequest('v1/admin/password', this.user)
      .subscribe((data: any) => {
        if (data.success) {
          this.service.showToasterSuccess(data.message)
        } else {
          this.service.showToasterError(data.message)
        }
      })
  }

  checkPasswords() { // here we have the 'passwords' group
  let pass = this.user['password'];
  let confirmPass = this.user['password'];

  return pass === confirmPass ? null : { notSame: true }     
}
}
