import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/api.service'

@Component({
  selector: 'app-admin-add-teacher',
  templateUrl: './admin-add-teacher.component.html',
  styleUrls: ['./admin-add-teacher.component.scss']
})
export class AdminAddTeacherComponent implements OnInit {
  _id: string
  user: any = {}
  btnLabel: string
  schoolId: string
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: ApiService
    ) { }

  ngOnInit() {
    this.schoolId = JSON.parse(localStorage.getItem('user'))._id
    this.route.params.subscribe(params => {
      this._id = params.id
    })
    if (this._id != 'add') {
      this.getTeacherData()
    } else {
      this.btnLabel = 'Invite Teacher'
    }
  }

  getTeacherData() {
    this.service.getRequest('v1/admin/teacher/' + this._id)
      .subscribe((data: any) => {
        if (data.success && data.user) {
          this.user = {
            name: data.user.name,
            email: data.user.email,
            phone: data.user.phone,
            campus: data.user.campus,
          }

          this.btnLabel = 'Update Teacher'
          
        } else {
          this._id = 'add'
          this.service.showToasterError(data.message)
        }
      })
  }


  submit() {
    if (this._id == 'add') {
      this.user.schoolId = this.schoolId
      this.service.postRequest('v1/admin/teacher', this.user)
        .subscribe((data: any) => {
          if (data.success) {
            this.service.showToasterSuccess(data.message)
            this.router.navigate(['admin/teacher'])
          } else {
            this.service.showToasterError(data.message)
          }
        })
    } else {
      this.service.updateRequest('v1/admin/teacher/' + this._id, this.user)
        .subscribe((data: any) => {
          if (data.success) {
            this.service.showToasterSuccess(data.message)
            this.router.navigate(['admin/teacher'])
          } else {
            this.service.showToasterError(data.message)
          }
        })
    }
  }

  goToteachers() {
    this.router.navigate(['admin/teacher'])
  }
}
