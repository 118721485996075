import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service'

@Component({
  selector: 'app-student-login-page',
  templateUrl: './student-login-page.component.html',
  styleUrls: ['./student-login-page.component.scss']
})
export class StudentLoginPageComponent implements OnInit {

  user: any = {}
  constructor(private router: Router, private service: ApiService) { }

  ngOnInit() {
    if (localStorage.getItem('role') == 'student')
      this.router.navigate(['studentDashboard'])
  }

  submit() {
    this.service.postRequest('v1/student/login', this.user)
      .subscribe((data: any) => {
        if (data.success) {
          this.user = {}
          localStorage.setItem('user', JSON.stringify(data.student))
          localStorage.setItem('role', 'student')
          this.router.navigate(['studentDashboard'])
        } else {
          this.service.showToasterError(data.message)
        }
      })
  }
}
