import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  user = {
    profilePic: ''
  };
  activeTab: any;
  name: string
  @Input('activeTabIndex') activeTabIndex;
  baseUrl: string;

  constructor(private router: Router, private service: ApiService) {
    this.baseUrl = this.service.baseUrl;
  }

  ngOnInit() {
    this.activeTab = this.activeTabIndex
    if (localStorage.getItem('role') == 'teacher') {
      this.user = JSON.parse(localStorage.getItem('user'))
      this.name = this.user['name']
    } else {
      this.router.navigate(['/teacherLogin']);
    }

    /** get changes from profile to sidebar service */
    this.service.currentMessage.subscribe(status => {
      if(status) {
        this.user = JSON.parse(localStorage.getItem('user'))
        this.name = this.user['name']
      }
    })
  }

  goTodashboard() {
    this.router.navigate(['/dashboard']);
  }
  goToteachClasses() {
    this.router.navigate(['/teachclasses']);
  }
  goToLessons() {
    this.router.navigate(['/teachLessons']);
  }
  goTosettings() {
    this.router.navigate(['/settings']);
  }
  goToteacherProfile() {
    this.router.navigate(['/teacherProfile']);
  }
  goToteacherChangePassword() {
    this.router.navigate(['/teacherChangePassword']);
  }

  logout() {
    localStorage.clear()
    this.router.navigate(['']);

  }

}