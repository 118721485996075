import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "../../services/api.service";

@Component({
  selector: "app-school-register",
  templateUrl: "./school-register.component.html",
  styleUrls: ["./school-register.component.scss"]
})
export class SchoolRegisterComponent implements OnInit {
  user: any = {};
  constructor(private router: Router, private service: ApiService) {}

  ngOnInit() {
    if (
      !localStorage.getItem("user") ||
      localStorage.getItem("role") != "admin"
    ) {
      this.router.navigate(["/admin"]);
    }
  }

  onBoard() {
    this.service
      .postRequest("v1/sales/register", this.user)
      .subscribe((data: any) => {
        if (data.success) {
          this.user = {};
          this.service.showToasterSuccess(data.message);
          this.router.navigate(["admin"]);
        } else {
          this.service.showToasterError(data.message);
        }
      });
  }
}
