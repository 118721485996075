import { Component, Inject, OnInit } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from "@angular/material/dialog";
// import * as jsPDF from "jspdf";

@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"]
})
export class ModalComponent implements OnInit {
  modalBody = {
    title: "",
    body: ""
  };
  defaultButton = "Yes";
  downloadData = {};

  constructor(
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;
    this.modalBody = data;
  }

  ngOnInit() {
    if (
      this.modalBody["title"] === "Welcome" ||
      this.modalBody["title"] === "Thank you"
    ) {
      this.defaultButton = "Got it";
    }
    // if(this.modalBody['data']) {
    //   console.log(this.modalBody['data'], '---here');
    //   this.downloadData= this.modalBody['data'];
    // }
  }

  // downloadPdf() {
  //   let doc = new jsPDF(this.downloadData);
  //   doc.save("conference.pdf");
  // }

  confirm() {
   
    if (this.defaultButton === "Got it") {
      localStorage.removeItem("inviteModal");
      this.cancel();
    } else {
      this.dialogRef.close(true);
    }
  }
  cancel() {
    this.dialogRef.close(false);
  }
}
