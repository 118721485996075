import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Student
import { StudentRegisterComponent } from './Student/student-register/student-register.component';
import { StudentLoginPageComponent } from './Student/student-login-page/student-login-page.component';
import { StudentDashboardComponent } from './Student/student-dashboard/student-dashboard.component';
import { LessonsComponent } from './Student/lessons/lessons.component';
import { GradeBookComponent } from './Student/grade-book/grade-book.component';
import { ViewLessonComponent } from './Student/view-lesson/view-lesson.component';
import { StudentProfileComponent } from './Student/student-profile/student-profile.component';

// Web
import { SetPasswordComponent } from './Web/set-password/set-password.component';
import { SchoolRegisterComponent } from './Web/school-register/school-register.component';
import { HomePageComponent } from './Web/home-page/home-page.component';
import { FreeDemoComponent } from './Web/free-demo/free-demo.component';
import { ContactUsComponent } from './Web/contact-us/contact-us.component';
import { LoginPageComponent } from './Web/login-page/login-page.component';
import { SimulationCreateComponent } from './Web/simulation-create/simulation-create.component';
import { ForgotPasswordComponent } from './Web/forgot-password/forgot-password.component';
import { ConferencePageComponent } from './Web/conference-page/conference-page.component';
import { StaticPagesComponent } from './Components/static-pages/static-pages.component';

// Teacher
import { DashboardComponent } from './Teacher/dashboard/dashboard.component';
import { TeachClassesComponent } from './Teacher/teach-classes/teach-classes.component';
import { TeachLessonsComponent } from './Teacher/teachLessons/teachLessons.component';
import { AddLessonComponent } from './Teacher/add-lesson/add-lesson.component';
import { AddClassesComponent } from './Teacher/add-classes/add-classes.component';
import { TeacherProfileComponent } from './Teacher/teacher-profile/teacher-profile.component';
import { TeacherLoginComponent } from './Teacher/teacher-login/teacher-login.component';
import { TeacherChangePasswordComponent } from './Teacher/teacher-change-password/teacher-change-password.component';
import { ViewClassComponent } from './Teacher/view-class/view-class.component';

// ADMIN
import { AdminLoginComponent } from './Admin/admin-login/admin-login.component';
import { AdminDashboardComponent } from './Admin/admin-dashboard/admin-dashboard.component';
import { AdminProfileComponent } from './Admin/admin-profile/admin-profile.component';
import { AdminPasswordComponent } from './Admin/admin-password/admin-password.component';
import { AdminTeacherComponent } from './Admin/admin-teacher/admin-teacher.component';
import { AdminAddTeacherComponent } from './Admin/admin-add-teacher/admin-add-teacher.component';
import { AdminComponent } from './Admin/admin/admin.component';
// <<<<<<< HEAD
// <<<<<<< HEAD
import { EducatorComponent } from './educator/educator.component';
import { AdvertismComponent } from './advertism/advertism.component';

const routes: Routes = [

  { path: '', pathMatch: 'full', component: HomePageComponent },
  { path: '*', component: HomePageComponent },
  { path: 'switchersim_info', component:AdvertismComponent},
  { path: 'STN2020', component: ConferencePageComponent },
  { path: 'loginPage', component: LoginPageComponent },
  { path: 'freeDemo', component: FreeDemoComponent },
  { path: 'contactUs', component: ContactUsComponent },
// <<<<<<< HEAD
// <<<<<<< HEAD
  { path: 'educators', component: EducatorComponent },
// =======
// >>>>>>> bc96d1a63219e5e2eda4dadbb18b07582de08d25
// =======
// >>>>>>> bc96d1a63219e5e2eda4dadbb18b07582de08d25
  { path: 'password/:type/:id', component: SetPasswordComponent },
  { path: 'forgot-password/:type', component: ForgotPasswordComponent },
  
  { path: 'admin', component: AdminComponent },
  { path: 'simulation', component: SimulationCreateComponent },
  { path: 'sales', component: SchoolRegisterComponent },
  { path: 'page/:type', component: StaticPagesComponent },

  { path: 'teacherLogin', component: TeacherLoginComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'teachclasses', component: TeachClassesComponent },
  { path: 'teachLessons', component: TeachLessonsComponent },
  { path: 'addLesson', component: AddLessonComponent },
  { path: 'viewClass/:id', component: ViewClassComponent },
  { path: 'addClasses', component: AddClassesComponent },
  { path: 'addClasses/:id', component: AddClassesComponent },
  { path: 'teacherProfile', component: TeacherProfileComponent },
  { path: 'teacherChangePassword', component: TeacherChangePasswordComponent },
 
  { path: 'student-register', component: StudentRegisterComponent },
  { path: 'studentLoginPage', component: StudentLoginPageComponent },
  { path: 'studentDashboard', component: GradeBookComponent },
  // { path: 'studentDashboard', component: StudentDashboardComponent },
  { path: 'lessons', component: LessonsComponent },
  { path: 'gradeBook', component: GradeBookComponent },
  { path: 'viewLesson/:id', component: ViewLessonComponent },
  { path: 'studentProfile', component: StudentProfileComponent },

  { path: 'admin/login', component: AdminLoginComponent },
  { path: 'admin/dashboard', component: AdminDashboardComponent },
  { path: 'admin/teacher', component: AdminTeacherComponent },
  { path: 'admin/profile', component: AdminProfileComponent },
  { path: 'admin/password', component: AdminPasswordComponent },
  { path: 'admin/teacher/:id', component: AdminAddTeacherComponent },

];

@NgModule({
// <<<<<<< HEAD
  imports: [RouterModule.forRoot(routes,
    {
      // useHash: true,
      scrollPositionRestoration: "enabled"
    }
     )],
  exports: [RouterModule]
})
  
// =======
//   imports: [RouterModule.forRoot(routes, {useHash: true})],
//   exports: [RouterModule]
// })

// >>>>>>> bc96d1a63219e5e2eda4dadbb18b07582de08d25
export class AppRoutingModule { }
