import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-bottom',
  templateUrl: './footer-bottom.component.html',
  styleUrls: ['./footer-bottom.component.scss']
})
export class FooterBottomComponent implements OnInit {

  currentDate:any;
  constructor() { 
    let date = new Date();
    this.currentDate = date.getFullYear();
  }

  ngOnInit() {
  }

}
