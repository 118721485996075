import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "../../services/api.service";
import { HttpParams } from "@angular/common/http";
import { MatDialog } from "@angular/material/dialog";
import { TmodalComponent } from "../tmodal/tmodal.component";
@Component({
  selector: "app-add-classes",
  templateUrl: "./add-classes.component.html",
  styleUrls: ["./add-classes.component.scss"],
})
export class AddClassesComponent implements OnInit {
  randomCode = Math.random().toString(36).substring(2, 6);
  editRequest = false;
  studentsLessons = [];
  classModel = {
    code: this.randomCode,
    name: "",
  };
  studentModel = {};
  allLessons = [];
  checklistdata = [];
  assementlist: any;
  codepay: any;
  studentname: any;
  classlist: any;
  id: any;
  broadname: any;
  listdata: any;
  classCode: any;
  liststudent: any;
  lessonList = [
    "Lesson 1",
    "Lesson 2",
    "Lesson 3",
    "Lesson 4",
    "Lesson 5",
    "Lesson 6",
    "Lesson 7",
    "Lesson 8",
  ];
  datalist: any;
  studentlessonlist: any;
  lessonlist: any;
  checked: boolean;
  chartlist: any;
  checkstudent: boolean = false;
  teacherData: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: ApiService,
    public dialog: MatDialog
  ) {
    this.broadname = JSON.parse(localStorage.getItem("name"));
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      if (params && params.id) {
        this.editRequest = true;
        this.id = params.id;
        this.getClassById(params.id);
      } else {
        this.editRequest = false;
        this.classModel = this.classModel;
      }
    });

    this.service
      .getAllRequest("v1/simulation/getAllList")
      .subscribe((data: any) => {
        this.lessonlist = data.simulation;
        console.log(this.lessonlist, "88888888888888888888888888");
        this.service
          .getRequest(`v1/studentLesson/getLessonsByClass/${this.id}`)
          .subscribe((data1: any) => {
            const teacherData = JSON.parse(localStorage.getItem("user"));
            console.log("ert", teacherData);
            this.lessonlist.map((val) => {
              if (
                teacherData.lessons.find(
                  (item) => "" + item.simulationId === "" + val._id
                ).targetScore
              ) {
                val.targetScore =
                  teacherData.lessons.find(
                    (item) => "" + item.simulationId === "" + val._id
                  ).targetScore || 0;
              } else {
                val.targetScore = 0;
              }
              if (data1.studentLesson.indexOf(String(val._id)) >= 0) {
                val.checked = true;
                this.checklistdata.push(val);
              } else {
                val.checked = false;
              }

              return val;
            });
            console.log("", this.lessonlist);

            //  for(var i =0;i<data1.length;i++)
            //  {
            //   if(data.simulation[i]._id == data1.studentLesson)
            //   {
            //     this.checked = true
            //   }
            //   else[
            //     this.checked = false
            //   ]

            //  }
          });
      });
  }

  getClassById(classId) {
    this.service
      .getRequest(`v1/teacher/getClassById/${classId}`)
      .subscribe((data: any) => {
        if (data.success) {
          this.assementlist = data.class.teacherId.lessons;
          this.codepay = data.class.code;
          this.getAllcodelist();
          this.getstudentdata();
          // console.log("class-code",data);
          this.getStudentsLessons(data.class);
          this.classModel = {
            code: data.class.code,
            name: data.class.name,
          };
        } else {
          this.service.showToasterError(data.message);
        }
      });
  }

  getstudentdata() {
    this.service
      .getRequest(`v1/student/getStudents/${this.id}?code=${this.codepay}`)
      .subscribe((data: any) => {
        this.service
          .getRequest(`v1/studentLesson/getLessonsByClass/${this.id}`)
          .subscribe((data1: any) => {
            this.studentlessonlist = data1.studentLesson;
            console.log("data", data1.studentLesson);
          });
        this.liststudent = data.student;
        console.log("getallstudent", data.student);
      });
  }

  getAllcodelist() {
    const payload = new HttpParams().set("code", this.codepay);

    this.service
      .postRequest(`v1/student/getAllCodes`, payload)
      .subscribe((data: any) => {
        this.studentname = data.data.students;
        this.classlist = data.data.classes;
        this.checkstudent = true;
        console.log("classlist", this.classlist);
        for (let i = 0; i < this.classlist.length; i++) {
          if (this.broadname.code === this.classlist[i].code) {
            // this.classlist= this.classlist.splice(i,2)
            this.classlist = this.classlist.filter(
              (item) => item.code !== this.broadname.code
            );
          }
        }

        // this.classlist = this.classlist.splice(this.broadname)
        // this.classlist = this.classlist.filter(item => item !== this.broadname);
        console.log("getcartlist", this.classlist);
      });
  }

  getStudentsLessons(classData) {
    let teacher = JSON.parse(localStorage.getItem("user"));
    this.service
      .getRequest(
        `v1/student/getStudentsLessons/${teacher._id}?code=${classData.code}`
      )
      .subscribe((data: any) => {
        if (data.success) {
          this.studentsLessons = data["student"];
          this.allLessons = classData["teacherId"]["lessons"];
        } else {
          this.service.showToasterError(data.message);
        }
      });
  }
  studentChange(student) {
    if (student) {
      this.studentModel["ids"].push(student);
    }
  }
  lessonChange(lesson) {
    this.studentModel["lessonAssigned"].push(lesson);
  }
  onBoard() {
    this.route.params.subscribe((params) => {
      if (params && params.id) {
        this.editRequest = true;
        this.editClass(params.id);
      } else {
        this.editRequest = false;
        this.addClass();
      }
    });
  }

  checklist(list, e) {
    // console.log(e);
    if (e.checked) {
      this.checklistdata.push(list);
    } else {
      this.checklistdata = this.checklistdata.filter(
        (list_id) => list_id !== list
      );
    }
    console.log("sfdfsds", this.checklistdata);
  }

  updatelist() {
    const data = {
      classId: this.id,
      classCode: this.codepay,
      lessons: this.checklistdata,
    };

    this.service
      .postRequest(`v1/studentLesson/updateLessonsByClassCode`, data)
      .subscribe((data: any) => {
        // console.log("dat",data);
        // this.checklistdata = [];
        if (data.success) {
          this.service.showToasterSuccess("submitted successfully!");
        }
      });
  }

  classcode(id) {
    this.classCode = id;
  }
  selectstudent(_id, code) {
    this.chartlist = this.classlist.filter((ele) => ele.code == code.value);
    debugger;
    const dialogRef = this.dialog.open(TmodalComponent, {
      width: "40%",
      height: "40%",
      data: {
        title: "Transfer Test Student",
        body: `You are sure you want to transfer this student to ${this.chartlist[0].name} ?`,
      },
    });
    const payload = {
      Id: _id,
      classId: this.id,
      code: code.value,
    };
    localStorage.setItem("payload", JSON.stringify(payload));
    this.getAllcodelist();
  }

  addClass() {
    let teacher = JSON.parse(localStorage.getItem("user"));
    this.classModel = Object.assign(
      {
        teacherId: teacher["_id"],
      },
      this.classModel
    );

    this.service
      .postRequest("v1/teacher/addClass", this.classModel)
      .subscribe((data: any) => {
        if (data.success) {
          this.service.showToasterSuccess(data.message);
          this.router.navigate(["teachclasses"]);
        } else {
          this.service.showToasterError(data.message);
        }
      });
  }

  editClass(classId) {
    let data = this.classModel;
    this.service
      .postRequest(`v1/teacher/updateClass/${classId}`, data)
      .subscribe((data: any) => {
        if (data.success) {
          this.service.showToasterSuccess(data.message);
          this.router.navigate(["teachclasses"]);
        } else {
          this.service.showToasterError(data.message);
        }
      });
  }
}
