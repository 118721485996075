import { BrowserModule } from '@angular/platform-browser';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MaterialModule } from './material/material.module';
import { FormsModule, ReactiveFormsModule, FormControl } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartsModule } from 'ng2-charts';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HeaderTopComponent } from './Components/header-top/header-top.component';
import { FooterBottomComponent } from './Components/footer-bottom/footer-bottom.component';

// Student
import { StudentLoginPageComponent } from './Student/student-login-page/student-login-page.component';
import { StudentDashboardComponent } from './Student/student-dashboard/student-dashboard.component';
import { LessonsComponent } from './Student/lessons/lessons.component';
import { StudentSidebarComponent } from './Student/student-sidebar/student-sidebar.component';
import { GradeBookComponent } from './Student/grade-book/grade-book.component';
import { ViewLessonComponent } from './Student/view-lesson/view-lesson.component';
import { StudentProfileComponent } from './Student/student-profile/student-profile.component';

// Web
import { SetPasswordComponent } from './Web/set-password/set-password.component';
import { SchoolRegisterComponent } from './Web/school-register/school-register.component';
import { HomePageComponent } from './Web/home-page/home-page.component';
import { FreeDemoComponent } from './Web/free-demo/free-demo.component';
import { ContactUsComponent } from './Web/contact-us/contact-us.component';
import { LoginPageComponent } from './Web/login-page/login-page.component';
import { SimulationCreateComponent } from './Web/simulation-create/simulation-create.component';
import { ConferencePageComponent } from './Web/conference-page/conference-page.component';

// Teacher
import { DashboardComponent } from './Teacher/dashboard/dashboard.component';
import { SidebarComponent } from './Teacher/sidebar/sidebar.component';
import { TeachClassesComponent } from './Teacher/teach-classes/teach-classes.component';
import { TeachLessonsComponent } from './Teacher/teachLessons/teachLessons.component';
import { AddLessonComponent } from './Teacher/add-lesson/add-lesson.component';
import { AddClassesComponent } from './Teacher/add-classes/add-classes.component';
import { TeacherProfileComponent } from './Teacher/teacher-profile/teacher-profile.component';
import { TeacherLoginComponent } from './Teacher/teacher-login/teacher-login.component';
import { TeacherChangePasswordComponent } from './Teacher/teacher-change-password/teacher-change-password.component';
import { ViewClassComponent } from './Teacher/view-class/view-class.component';

// ADMIN
import { AdminSidebarComponent } from './Admin/admin-sidebar/admin-sidebar.component';
import { AdminLoginComponent } from './Admin/admin-login/admin-login.component';
import { AdminDashboardComponent } from './Admin/admin-dashboard/admin-dashboard.component';
import { AdminProfileComponent } from './Admin/admin-profile/admin-profile.component';
import { AdminPasswordComponent } from './Admin/admin-password/admin-password.component';
import { AdminTeacherComponent } from './Admin/admin-teacher/admin-teacher.component';
import { AdminAddTeacherComponent } from './Admin/admin-add-teacher/admin-add-teacher.component';
import { StudentRegisterComponent } from './Student/student-register/student-register.component';
import { ModalComponent } from './Web/modal/modal.component';
import { SimModalComponent } from './Web/sim-modal/sim-modal.component';
import { AdminComponent } from './Admin/admin/admin.component';
import { ForgotPasswordComponent } from './Web/forgot-password/forgot-password.component';
import { StaticPagesComponent } from './Components/static-pages/static-pages.component';
// <<<<<<< HEAD
// <<<<<<< HEAD
import { EducatorComponent } from './educator/educator.component';
import { TmodalComponent } from './Teacher/tmodal/tmodal.component';
import { AdvertismComponent } from './advertism/advertism.component';
// =======
// >>>>>>> bc96d1a63219e5e2eda4dadbb18b07582de08d25
// =======
// >>>>>>> bc96d1a63219e5e2eda4dadbb18b07582de08d25

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    HeaderTopComponent,
    FooterBottomComponent,
    TeacherLoginComponent,
    StudentLoginPageComponent,
    ForgotPasswordComponent,
    HomePageComponent,
    DashboardComponent,
    SchoolRegisterComponent,
    SidebarComponent,
    AdminLoginComponent,
    SetPasswordComponent,
    AdminDashboardComponent,
    TeachClassesComponent,
    TeachLessonsComponent,
    AddLessonComponent,
    AddClassesComponent,
    TeacherProfileComponent,
    TeacherChangePasswordComponent,
    FreeDemoComponent,
    ContactUsComponent,
    AdminSidebarComponent,
    AdminProfileComponent,
    AdminPasswordComponent,
    AdminTeacherComponent,
    AdminAddTeacherComponent,
    StudentDashboardComponent,
    LessonsComponent,
    StudentSidebarComponent,
    GradeBookComponent,
    StudentRegisterComponent,
    ModalComponent,
    ViewClassComponent,
    ViewLessonComponent,
    StudentProfileComponent,
    SimulationCreateComponent,
    SimModalComponent,
    AdminComponent,
    ConferencePageComponent,
    StaticPagesComponent,
// <<<<<<< HEAD
// <<<<<<< HEAD
    EducatorComponent,
TmodalComponent,
AdvertismComponent,
// =======
// >>>>>>> bc96d1a63219e5e2eda4dadbb18b07582de08d25
// =======
// >>>>>>> bc96d1a63219e5e2eda4dadbb18b07582de08d25
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      maxOpened: 1, 
      autoDismiss: true
    }),
    HttpClientModule,
    ChartsModule
  ],
  providers: [],
  entryComponents: [ModalComponent, SimModalComponent,TmodalComponent],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
