import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/api.service'

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {
  user: any = {}
  type: string
  _id: string

  constructor(private router: Router, private route: ActivatedRoute, private service: ApiService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.type = params.type
      this._id = params.id
    })
  }

  changePassword() {
    this.user._id = this._id
    if (this.type == 'school') {
      this.service.postRequest('v1/admin/setpassword', this.user)
        .subscribe((data: any) => {
          if (data.success) {
            this.service.showToasterSuccess(data.message)
            this.router.navigate(['/admin/login'])
          } else {
            this.service.showToasterError(data.message)
          }
        })
    } else if (this.type == 'teacher') {
      this.service.postRequest('v1/teacher/setpassword', this.user)
        .subscribe((data: any) => {
          if (data.success) {
            this.service.showToasterSuccess(data.message)
            this.router.navigate(['/teacherLogin'])
          } else {
            this.service.showToasterError(data.message)
          }
        })
    } else if (this.type == 'student') {
      this.service.postRequest('v1/student/setpassword', this.user)
        .subscribe((data: any) => {
          if (data.success) {
            this.service.showToasterSuccess(data.message)
            this.router.navigate(['/studentLoginPage'])
          } else {
            this.service.showToasterError(data.message)
          }
        })
    }  else {
      this.service.showToasterError('Something wrong. Try again with correct link')
    }

  }

}
