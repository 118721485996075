import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service'

@Component({
  selector: 'app-teacher-change-password',
  templateUrl: './teacher-change-password.component.html',
  styleUrls: ['./teacher-change-password.component.scss']
})
export class TeacherChangePasswordComponent implements OnInit {

  user: any = {}
  userId: string
  constructor(private router: Router, private service: ApiService) { }

  ngOnInit() {
    this.userId = JSON.parse(localStorage.getItem('user'))._id
  }

  submit() {

    this.user._id = this.userId

    this.service.updateRequest('v1/teacher/password', this.user)
      .subscribe((data: any) => {
        if (data.success) {
          this.service.showToasterSuccess(data.message)
        } else {
          this.service.showToasterError(data.message)
        }
      })
  }

}
