import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "../../services/api.service";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from "@angular/material/dialog";
import { SimModalComponent } from "../../Web/sim-modal/sim-modal.component";

@Component({
  selector: "app-view-lesson",
  templateUrl: "./view-lesson.component.html",
  styleUrls: ["./view-lesson.component.css"]
})
export class ViewLessonComponent implements OnInit {
  videoSource: any;
  queryData: any;
  simInfo = {
    url: '',
    name: '',
    grade: '',
    score: null,
    timeStamp: null,
    description: '',
  };
  baseUrl = "";
  role = "";

  @ViewChild("videoPlayer", { static: false }) videoplayer: ElementRef;
  constructor(
    private service: ApiService,
    private router: Router,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) {
    const navigation = this.router.getCurrentNavigation();
    this.queryData = navigation.extras.state;
    this.baseUrl = this.service.baseUrl;
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.role = localStorage.getItem("role");
      if (params && params.id) {
        this.getSimulation(params.id);
      } else {
        if (this.role === "student") {
          this.router.navigate(["/lessons"]);
        } else {
          this.router.navigate(["/teachLessons"]);
        }
      }
    });
  }

  back() {
    if (this.role === "student") {
      this.router.navigate(["/lessons"]);
    } else if (this.role === "teacher") {
      this.router.navigate(["/teachLessons"]);
    }
  }
  getSimulation(simulationId) {
    this.service
      .getRequest(`v1/simulation/getById/${simulationId}`)
      .subscribe((data: any) => {
        if (data.success) {
          this.simInfo = data.simulation;
        } else {
          this.service.showToasterError(data.message);
        }
      });
  }

  toggleVideo() {
    this.videoplayer.nativeElement.play();
  }
  closeLesson() {
    let _role = localStorage.getItem("role");
    if (_role === "student") {
      /** update lesson */
      let lessonArray = this.queryData["student"]["viewedLesson"];
      let lessonExists = false;
      if (lessonArray.length) {
        lessonArray.map(lesson => {
          if (lesson["title"] === this.queryData["lesson"]["title"]) {
            lessonExists = true;
            return;
          }
        });
      }
      if (!lessonExists) {
        this.updateStudent();
      }
      this.router.navigate(["/lessons"]);
    } else {
      this.router.navigate(["/teachLessons"]);
    }
  }

  updateStudent() {
    let studentId = this.queryData["student"]["_id"];
    let update = {
      viewedLesson: this.queryData["lesson"]
    };
    this.service
      .updateRequest(`v1/student/updateLesson/${studentId}`, update)
      .subscribe((data: any) => {
        if (data.success) {
          this.service.showToasterSuccess(data.message);
        } else {
          this.service.showToasterError(data.message);
        }
      });
  }
}
