import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  baseUrl: any = environment.baseUrl;
  private messageSource = new BehaviorSubject(false);
  currentMessage = this.messageSource.asObservable();

  constructor(private http: HttpClient, private toastr: ToastrService) { }

  postRequest(path: string, obj: any) {
    return this.http.post<any>(`${this.baseUrl}${path}`, obj);
  }

  updateRequest(path: string, obj: any) {
    return this.http.put<any>(`${this.baseUrl}${path}`, obj);
  }

  getRequest(path: string) {
    return this.http.get<any>(`${this.baseUrl}${path}`);
  } 

  getAllRequest(path: string) {
    return this.http.get<any>(`${this.baseUrl}${path}`);
  }

  deleteRequest(path: string) {
    return this.http.delete<any>(`${this.baseUrl}${path}`);
  }

  showToasterSuccess(message: string) {
    this.toastr.success(message);
  }

  showToasterError(message: string) {
    this.toastr.error(message);
  }

  changeMessage(status: boolean) {
    this.messageSource.next(status)
  }
}
